define("discourse/plugins/discourse-presence/discourse/components/composer-presence-display", ["exports", "@ember/component", "discourse/plugins/discourse-presence/discourse/lib/presence", "@ember/runloop", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/service", "@ember/template-factory"], function (_exports, _component, _presence, _runloop, _decorators, _computed, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <div class="presence-users">
      <div class="presence-avatars">
        {{#each this.presenceUsers as |user|}}
          {{avatar user imageSize="small"}}
        {{/each}}
      </div>
      <span class="presence-text">
        <span class="description">
          {{~#if this.isReply~}}
            {{i18n "presence.replying" count=this.presenceUsers.length}}
          {{~else~}}
            {{i18n "presence.editing" count=this.presenceUsers.length}}
          {{~/if~}}
        </span>
        <span class="wave">
          <span class="dot">.</span>
          <span class="dot">.</span>
          <span class="dot">.</span>
        </span>
      </span>
    </div>
  {{/if}}
  */
  {
    "id": "fKramLOc",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,0,\"presence-users\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"presence-avatars\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"presenceUsers\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,3],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"presence-text\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"description\"],[12],[41,[30,0,[\"isReply\"]],[[[1,[28,[35,4],[\"presence.replying\"],[[\"count\"],[[30,0,[\"presenceUsers\",\"length\"]]]]]]],[]],[[[1,[28,[35,4],[\"presence.editing\"],[[\"count\"],[[30,0,[\"presenceUsers\",\"length\"]]]]]]],[]]],[13],[1,\"\\n      \"],[10,1],[14,0,\"wave\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"each\",\"-track-array\",\"avatar\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-presence/discourse/components/composer-presence-display.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_dec = (0, _decorators.default)("model.topic.id"), _dec2 = (0, _decorators.default)("model.topic.id"), _dec3 = (0, _decorators.on)("didInsertElement"), _dec4 = (0, _decorators.default)("model.post.id", "editingUsers.@each.last_seen", "users.@each.last_seen", "isReply", "isEdit"), _dec5 = (0, _decorators.observes)("model.reply", "model.title"), _dec6 = (0, _decorators.observes)("model.whisper"), _dec7 = (0, _decorators.observes)("model.action", "model.topic.id"), _dec8 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    // Passed in variables
    presenceManager: (0, _service.inject)(),
    users(topicId) {
      return this.presenceManager.users(topicId);
    },
    editingUsers(topicId) {
      return this.presenceManager.editingUsers(topicId);
    },
    isReply: (0, _computed.readOnly)("model.replyingToTopic"),
    isEdit: (0, _computed.readOnly)("model.editingPost"),
    subscribe() {
      this.presenceManager.subscribe(this.get("model.topic.id"), _presence.COMPOSER_TYPE);
    },
    presenceUsers(postId, editingUsers, users, isReply, isEdit) {
      if (isEdit) {
        return editingUsers.filterBy("post_id", postId);
      } else if (isReply) {
        return users;
      }
      return [];
    },
    shouldDisplay: (0, _computed.gt)("presenceUsers.length", 0),
    typing() {
      (0, _runloop.throttle)(this, this._typing, _presence.KEEP_ALIVE_DURATION_SECONDS * 1000);
    },
    _typing() {
      if (!this.isReply && !this.isEdit || !this.get("model.composerOpened")) {
        return;
      }
      let data = {
        topicId: this.get("model.topic.id"),
        state: this.isEdit ? _presence.EDITING : _presence.REPLYING,
        whisper: this.get("model.whisper"),
        postId: this.get("model.post.id"),
        presenceStaffOnly: this.get("model._presenceStaffOnly")
      };
      this._prevPublishData = data;
      this._throttle = this.presenceManager.publish(data.topicId, data.state, data.whisper, data.postId, data.presenceStaffOnly);
    },
    cancelThrottle() {
      this._cancelThrottle();
    },
    composerState() {
      if (this._prevPublishData) {
        this.presenceManager.publish(this._prevPublishData.topicId, _presence.CLOSED, this._prevPublishData.whisper, this._prevPublishData.postId);
        this._prevPublishData = null;
      }
    },
    closeComposer() {
      this._cancelThrottle();
      this._prevPublishData = null;
      this.presenceManager.cleanUpPresence(_presence.COMPOSER_TYPE);
    },
    _cancelThrottle() {
      if (this._throttle) {
        (0, _runloop.cancel)(this._throttle);
        this._throttle = null;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "users", [_dec], Object.getOwnPropertyDescriptor(_obj, "users"), _obj), _applyDecoratedDescriptor(_obj, "editingUsers", [_dec2], Object.getOwnPropertyDescriptor(_obj, "editingUsers"), _obj), _applyDecoratedDescriptor(_obj, "subscribe", [_dec3], Object.getOwnPropertyDescriptor(_obj, "subscribe"), _obj), _applyDecoratedDescriptor(_obj, "presenceUsers", [_dec4], Object.getOwnPropertyDescriptor(_obj, "presenceUsers"), _obj), _applyDecoratedDescriptor(_obj, "typing", [_dec5], Object.getOwnPropertyDescriptor(_obj, "typing"), _obj), _applyDecoratedDescriptor(_obj, "cancelThrottle", [_dec6], Object.getOwnPropertyDescriptor(_obj, "cancelThrottle"), _obj), _applyDecoratedDescriptor(_obj, "composerState", [_dec7], Object.getOwnPropertyDescriptor(_obj, "composerState"), _obj), _applyDecoratedDescriptor(_obj, "closeComposer", [_dec8], Object.getOwnPropertyDescriptor(_obj, "closeComposer"), _obj)), _obj))));
});